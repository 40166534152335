import firebase from "firebase/app";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBGWXkJTM76DGl8MW_85j05EQKe8fen1Us",
  authDomain: "table-mvp.firebaseapp.com",
  projectId: "table-mvp",
  storageBucket: "table-mvp.appspot.com",
  messagingSenderId: "374495921995",
  appId: "1:374495921995:web:7f7c751ee6904a9166cace",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

export const db = firebaseApp.firestore();
