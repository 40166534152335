<template>
  <div class="container mx-auto max-w-6xl flex flex-col justify-start items-start gap-10 py-10">
    <div class="w-full inline-flex justify-center items-center gap-10">
      <div class="flex flex-col gap-1">
        <label for="search">Search</label>
        <div class="w-full inline-flex justify-start items-start gap-1">
          <div>
            <input
              class="w-96 p-2 rounded border border-gray-200 focus:outline-none"
              type="text"
              v-model="searchKey"
              placeholder="Search exact match entries..."
            />
            <!-- list of checkboxes to set which columns to search -->
            <!-- <div class="inline-flex gap-1">
              <div class="w-full inline-flex justify-start items-center gap-1">
                <input
                  class="p-2 rounded border border-gray-200 focus:outline-none"
                  type="checkbox"
                  id="search-columns"
                  v-model="searchColumns"
                />
                <label for="search-columns" class="w-full">
                  <span class="text-sm"> Search all columns </span>
                </label>
              </div>
            </div> -->
          </div>
          <button @click="search" class="px-5 py-2 bg-indigo-700 hover:bg-indigo-600 text-white">
            Search
          </button>
        </div>
      </div>

      <div class="w-full flex flex-col gap-2">
        <label for="upload">Upload a CSV file</label>
        <input class="cursor-pointer" type="file" ref="csvFile" accept=".csv" @input="parseFile" />
      </div>
    </div>

    <div class="w-full text-sm font-medium">
      <!-- table wrapper -->
      <h1>table here</h1>
      <VueGoodTable
        :rows="rows"
        :columns="columns"
        :fixed-header="true"
        :search-options="{
          enabled: true,
        }"
        compactMode
        :pagination-options="paginationOptions"
      />
    </div>

    <div>
      <!-- modals wrapper -->
    </div>

    <div
      v-if="loading"
      class="w-screen h-screen fixed top-0 left-0 bg-white bg-opacity-70 z-50 flex justify-center items-center"
    >
      <p class="animate-pulse text-2xl font-bold text-indigo-500">Processing...</p>
      <!-- loading -->
    </div>
  </div>
</template>

<script>
import { db } from "./db";

import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";

export default {
  name: "MainComponent",

  created() {
    // db.collection("docs")
    //   .get()
    //   .then((res) => {
    //     console.log(res);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  },

  data() {
    return {
      loading: false,

      searchKey: "",

      columns: [
        {
          label: "Vendor",
          field: "vendor",
        },
        {
          label: "Category",
          field: "category",
        },

        {
          label: "SKU",
          field: "sku",
        },

        {
          label: "Description",
          field: "description",
        },

        {
          label: "Volume",
          field: "volume",
          type: "number",
        },

        {
          label: "Power Supply",
          field: "powerSupply",
        },

        {
          label: "Purchase Price",
          field: "purchPrice",
          type: "number",
        },

        {
          label: "List Price",
          field: "listPrice",
          type: "number",
        },
      ],

      rows: [],

      toSave: [],

      paginationOptions: {
        enabled: true,
        mode: "records",
        perPage: 5,
        position: "top",
        perPageDropdown: [10, 20, 50, 100],
        dropdownAllowAll: false,
        setCurrentPage: 1,
        // jumpFirstOrLast: true,
        // firstLabel: "<<",
        // lastLabel: ">>",
        // nextLabel: "",
        // prevLabel: "",
        rowsPerPageLabel: "Per page",
        ofLabel: "of",
        pageLabel: "page",
        allLabel: "All",
      },
    };
  },

  components: {
    VueGoodTable,
  },

  methods: {
    parseFile() {
      const file = this.$refs.csvFile.files[0];
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = (e) => {
        const csv = e.target.result;
        let data = csv.replaceAll('\r', '').split("\n").map((row) => row.split(","));
        // console.log(data);

        const expectedFields = ['vendor','category','sku','description','volume','powerSupply','purchPrice','listPrice']
        const fields = data[0];
        if(fields) {
          let csvIsValid = true;
          expectedFields.forEach((expectedField, i) => {
            if(!fields[i]) {
              csvIsValid = false;
              console.log(`Missing field '${expectedField}'`);
            }
            if(fields[i] !== expectedField) {
              csvIsValid = false;
              console.log(`Field name error. '${expectedField}' was expect, but receive '${fields[i]}'`);
            }
          })
          if(!csvIsValid) {
            return alert("Invalid CSV file. Please check your file and try again.");
          }
        } else {
          return alert("Invalid CSV file. Please check your file and try again.");
        }

        data = data.map((row) => {
          return {
            vendor: row[0],
            category: row[1],
            sku: row[2],
            description: row[3],
            volume: row[4],
            powerSupply: row[5],
            purchPrice: row[6],
            listPrice: row[7],
          };
        });

        data.shift();
        data.pop();

        this.saveToFirestore(data);
      };
    },

    saveToFirestore(data) {
      this.loading = true;
      const batch = db.batch();
      data.forEach((doc) => {
        var docRef = db.collection("docs").doc();
        batch.set(docRef, doc);
      });
      batch
        .commit()
        .then(() => {
          this.loading = false;
          this.$refs.csvFile.value = "";
          alert(` Success! \n ${data.length} record(s) successfully added to the database.`);
          this.rows = data;
        })
        .catch((err) => {
          this.loading = false;
          this.$refs.csvFile.value = "";
          alert(` Error! \n ${err}`);
        });
    },

    search() {
      if (this.searchKey.trim().length === 0) {
        return alert("Please enter a valid search key.");
      }

      const resultRows = [];

      this.loading = true;

      db.collection("docs")
        .where("vendor", "==", this.searchKey)
        .get()
        .then((res) => {
          res.forEach((doc) => {
            resultRows.push(doc.data());
          });
        })
        .catch((err) => {
          this.loading = false;
          return alert(` Error! \n ${err}`);
        });

      db.collection("docs")
        .where("category", "==", this.searchKey)
        .get()
        .then((res) => {
          res.forEach((doc) => {
            resultRows.push(doc.data());
          });
        })
        .catch((err) => {
          this.loading = false;
          return alert(` Error! \n ${err}`);
        });

      db.collection("docs")
        .where("sku", "==", this.searchKey)
        .get()
        .then((res) => {
          res.forEach((doc) => {
            resultRows.push(doc.data());
          });
        })
        .catch((err) => {
          this.loading = false;
          return alert(` Error! \n ${err}`);
        });

      db.collection("docs")
        .where("description", "==", this.searchKey)
        .get()
        .then((res) => {
          res.forEach((doc) => {
            resultRows.push(doc.data());
          });
        })
        .catch((err) => {
          this.loading = false;
          return alert(` Error! \n ${err}`);
        });

      db.collection("docs")
        .where("volume", "==", this.searchKey)
        .get()
        .then((res) => {
          res.forEach((doc) => {
            resultRows.push(doc.data());
          });
        })
        .catch((err) => {
          this.loading = false;
          return alert(` Error! \n ${err}`);
        });

      db.collection("docs")
        .where("powerSupply", "==", this.searchKey)
        .get()
        .then((res) => {
          res.forEach((doc) => {
            resultRows.push(doc.data());
          });
        })
        .catch((err) => {
          this.loading = false;
          return alert(` Error! \n ${err}`);
        });

      db.collection("docs")
        .where("purchPrice", "==", this.searchKey)
        .get()
        .then((res) => {
          res.forEach((doc) => {
            resultRows.push(doc.data());
          });
        })
        .catch((err) => {
          this.loading = false;
          return alert(` Error! \n ${err}`);
        });

      db.collection("docs")
        .where("listPrice", "==", this.searchKey)
        .get()
        .then((res) => {
          res.forEach((doc) => {
            resultRows.push(doc.data());
          });
          this.loading = false;
          if (resultRows.length === 0) {
            return alert("No results found.");
          }
          this.rows = resultRows;
        })
        .catch((err) => {
          this.loading = false;
          return alert(` Error! \n ${err}`);
        });
    },
  },
};
</script>

<style></style>
